<template>
   <div>
     <v-banner single-line class="banner h5-carousel-new">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/Hardware-banner-h5.png"
        height="100%"
        :transition="false"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <div class="h5-banner-info" style="margin-top: -40px;">
        <div class="width-per-100">
          <div class="h5-banner-title" style="">XR硬件体系</div>
          <!-- <div class="h5-banner-text">丰富的硬件体系配合多样的展终端满足一切场景需求</div> -->
        </div>
      </div>
    </v-banner>

    <div v-for="(v,k) in list" :key="k" class="con con1" :style="{backgroundColor: v.bg}">
      <v-card flat color="transparent">
        <div class="ftyd-public-title-h5">
          <div>
            <span>{{ v.title }}</span>
            <span>{{ v.tip }}</span>
          </div>
          <p>{{ v.text }}</p>
        </div>
        <v-container fluid :style="{backgroundColor: v.bg}">
          <div class="box">
            <div class="box-img">
              <v-img :src="v.box.img" width="100%" min-height="200" contain>
                <template v-slot:placeholder><img-placeholder></img-placeholder></template>
              </v-img>
            </div>
            <ul class="right" :class="'right_'+v.box.ul.length">
              <li v-for="(l, i) in v.box.ul" :key="i">
                <img :src="l.icon" alt="">
                <span>{{ l.tit }}</span>
              </li>
            </ul>
          </div>
          <!-- <div class="box" v-else>
            <ul class="right">
              <li v-for="(l, i) in v.box.ul" :key="i">
                <img :src="l.icon" alt="">
                <span>{{ l.tit }}</span>
              </li>
            </ul>
            <img class="bimg" :src="v.box.img"/>
          </div> -->
        </v-container>
      </v-card>
    </div>
 
   </div>
</template>

<script>
export default {
  name: "OnlineRetailers",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      list: [
        {
          title: '飞天实景扫描仪',
          tip: 'FT-HoloScanner',
          text: '为室内场景提供三维空间重建能力',
          bg: '#FFFFFF',
          type: 'left',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Hardware-img-01.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-fcjj.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-sxjy.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-zlzs.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-xnly.png',
                tit: '虚拟旅游'
              },
            ]
          }
        },
        {
          title: '飞天感知交互台',
          tip: 'FT-Panel',
          text: '智能触控技术为核心的智能交互桌面式显示终端',
          bg: '#FBFBFB',
          type: 'right',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Hardware-img-02.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-fcjj.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-sxjy.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-zlzs.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-xnly.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-gysj.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-jgcl.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: '飞天立体交互墙',
          tip: 'FT-Wall',
          text: '在小间距LED的基础上增加交互装置 打造了替代投影教学方案的全新沉浸式教学系统',
          bg: '#fff',
          type: 'left',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Hardware-img-03.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-fcjj.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-sxjy.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-zlzs.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-xnly.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-gysx.png',
                tit: '工业实训'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-jgcl.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: '飞天沉浸互动屋',
          tip: 'FT-CAVE',
          text: 'FT-CAVE投影系统是由3个面以上硬质背投影墙组成是一套专门为虚拟仿真实验而打造的沉浸系统',
          bg: '#FBFBFB',
          type: 'right',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Hardware-img-04.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-fcjj.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-sxjy.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-zlzs.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-xnly.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-gysx.png',
                tit: '工业实训'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-jgcl.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: '交互驾驶模拟仓',
          tip: 'FT-SimConsole',
          text: '半实物虚拟仿真培训平台 是一套结合了算法、数据、工艺操作的大型中和模拟操作培训系统',
          bg: '#fff',
          type: 'left',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Hardware-img-05.png',
            ul: [
            
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-sxjy.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-gysx.png',
                tit: '工业实训'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-xnly.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-jgcl.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: '飞天立体交互桌',
          tip: 'FT-StationTable',
          text: '基于全息台用户可以在自由的空间内对模型进行多角度观察，并进行分析、修改、操控等交互',
          bg: '#FBFBFB',
          type: 'right',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Hardware-img-06.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-fcjj.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-sxjy.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-zlzs.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-xnly.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-gysj.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-jgcl.png',
                tit: '军工策略'
              }
            ]
          }
        },
        // {
        //   title: '飞天移动教学站',
        //   tip: 'FT-Motion',
        //   text: 'VR安全教育流动站 构筑具有强烈真实感和沉浸感的各种应急安全事件场景 让体验者自由参与到每个教育环节中',
        //   bg: '#fff',
        //   type: 'left',
        //   box: {
        //     img: 'https://h5.ophyer.cn/official_website/other/Hardware-img-07.png',
        //     ul: [
            
        //       {
        //         icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
        //         tit: '消防应急'
        //       },
        //       {
        //         icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
        //         tit: '实训教育'
        //       },
        //       {
        //         icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
        //         tit: '禁毒宣传'
        //       },
        //       {
        //         icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
        //         tit: '安全教育'
        //       },
        //       {
        //         icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
        //         tit: '工业设计'
        //       },
        //       {
        //         icon: 'https://h5.ophyer.cn/official_website/text-icon.png',
        //         tit: '行业体验'
        //       }
        //     ]
        //   }
        // },
        {
          title: '全息沙盘',
          tip: 'FT-HoloSand',
          text: '采用全息投影、多人互动传感等技术，在沙盘上呈现TB级别高精度三维模型。用户可以对沙盘上的模型进行编辑设计、改变颜色、移动缩放等操作',
          bg: '#fff',
          type: 'right',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Hardware-img-07.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-fcjj.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-sxjy.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-zlzs.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-xnly.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-gysj.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-jgcl.png',
                tit: '军工策略'
              }
            ]
          }
        },
        {
          title: '手势交互产品',
          tip: 'FT-Gesture Interaction',
          text: '通过监测人体手臂表皮的肌电神经信号，通过AI智能算法分析，反推手部动作，实现动作意图的解析。并将识别的动作作为人机交互的指令传递到VR的应用场景中，让体验更沉浸',
          bg: '#FBFBFB',
          type: 'left',
          box: {
            img: 'https://h5.ophyer.cn/official_website/other/Hardware-img-08.png',
            ul: [
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-fcjj.png',
                tit: '地产家居'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-sxjy.png',
                tit: '实训教育'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-zlzs.png',
                tit: '展览展示'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-xnly.png',
                tit: '虚拟旅游'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-gysj.png',
                tit: '工业设计'
              },
              {
                icon: 'https://h5.ophyer.cn/official_website/icon/Hardware-icon-jgcl.png',
                tit: '军工策略'
              }
            ]
          }
        },
      ]
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
  
  }
}
</script>

<style scoped lang='scss'>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.banner_title{
  font-size: 60px;
  color: #FFFFFF !important;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}

.con {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  .box {
    margin: -20px auto 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    padding: 0 20px;
    .box-img { 
      width: 100%;
      img {
        width: 100%;
        height: 270px;
        border-radius: 10px;
      }
      ::v-deep .v-image {
        border-radius: 10px !important;
      }
      .v-image {
        border-radius: 10px !important;
      }
      ::v-deep .v-image__image {
        border-radius: 10px !important;
      }
      .v-image__image {
        border-radius: 10px !important;
      }
    }
    ul {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      align-content: center;
      justify-content: center;
      justify-content: space-between !important;
      padding-left: 0;
      margin: 0 auto;
      width: 100%;
      li {
        width: 25%;
        height: 90px;
        margin: 0 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between !important;
        flex-direction: column;
        img {
          width: 60px;
        }
      }
    }
    // .right_4 {
    //   li {
    //     width: 20%;
    //     margin: 0;
    //   }
    // }
  }
}


</style>
